<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('CustMenu.Com.Action.ViewDownloadResponseToQueriesClarifications')"
        :cards="cards"
        v-slot="{ card }"
      >
        <pro-deck-card :title="card.title" expandable>
          <pro-query-details
            :queryText="card.queryText"
            :attachments="card.attachments"
          />
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProQueryDetails from "@/components/PROSmart/ProQueryDetails";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProQueryDetails },
  data() {
    return {
      cards: [],
    };
  },
  created() {
    this.$proSmart.tender
      .tendererGetResponseToQueryClarificationList(this, this.$route.params.id)
      .then((queryList) => {
        this.cards = queryList.map((query) => ({
          key: query.queryId,
          title: this.$proSmart.common.format.dateTime(
            new Date(query.dateSubmitted)
          ),
          queryText: query.queryText,
          attachments: query.attachments,
        }));
      });
  },
};
</script>
